<template>
  <div class="flex flex-col min-h-screen">
    <aide color="rose" />
    <!---- Header------->
    <div class="container">
      <div class="flex flex-wrap gap-y-5">
        <div class="w-full order-1">
          <div
            class="
              bg-grad bg-cover bg-no-repeat
              h-134
              md:h-272
              flex
              items-end
              md:items-center
              px-10
            "
          >
            <div class="flex-col w-full">
              <div
                class="
                  text-xl
                  md:text-4xl
                  font-EffraM
                  text-white text-center
                  mb-8
                  md:mb-4
                "
                style="line-height: 100%"
              >
                Une augmentation de la productivité et du revenu pour les
                praticiens <br /><br />tout en offrant du confort pour les
                patients
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---- End header------->

    <!---- simulation ------->
    <div class="grid grid-cols-1 md:grid-cols-5 container">
      <div
        class="
          flex flex-col
          md:col-span-2
          gap-y-5
          px-3
          py-3
          md:px-10 md:py-10
          bg-grayfoot
        "
      >
        <div
          class="
            flex
            justify-center
            md:justify-between
            pb-4
            border-0 border-b-2 border-solid border-gray-300
          "
        >
          <img
            src="/logos/pro/pro.png"
            alt="dok.ma"
            class="max-h-10 hidden md:block"
          />
          <div class="flex flex-col items-center md:flex-row gap-x-3">
            <!-- <h1 class="font-EffraR">Abonnement</h1>
            <a
              @click="$router.push('pricing')"
              class="
                cursor-pointer
                flex flex-row
                gap-x-1
                bg-dokBlue-ultra
                rounded-full
                px-3
                py-2
                text-white
              "
            >
              <h1 class="font-EffraR text-white">{{ coutDokMensuel }}</h1>
              <div class="flex flex-col gap-y-0">
                <span class="font-EffraR">DH</span>
                <span class="font-EffraL"> /Mois</span>
              </div>
            </a> -->
          </div>
        </div>

        <h1 class="font-EffraR text-left md:pb-10">
          Total d'économies potentielles
        </h1>

        <div
          class="
            flex
            justify-between
            pb-1
            md:pb-4
            border-0 border-b border-solid border-gray-300
          "
        >
          <label class="font-EffraL">Temps économisé par consultation :</label>
          <label class="font-EffraR text-xl"
            >{{ consultationTimeRes }} &nbsp;Min
          </label>
        </div>

        <div
          class="
            flex
            justify-between
            pb-1
            md:pb-4
            border-0 border-b border-solid border-gray-300
          "
        >
          <label class="font-EffraL">Économies mensuelles réalisées:</label>
          <label class="font-EffraR text-xl">{{
            convertToDH(totalEcoMonth)
          }}</label>
        </div>

        <div
          class="
            flex
            justify-between
            pb-1
            md:pb-4
            border-0 border-b border-solid border-gray-300
          "
        >
          <label class="font-EffraL">Économies annuelles réalisées:</label>
          <label class="font-EffraR text-xl">{{
            convertToDH(totalEcoYear)
          }}</label>
        </div>
      </div>

      <div
        class="
          flex flex-col
          md:col-span-3
          px-3
          py-3
          md:px-10 md:py-10
          gap-y-5
          md:gap-y-10
        "
      >
        <div
          class="
            flex flex-col
            md:pb-4
            border-0 border-b-2 border-solid border-gray-300
          "
        >
          <h1 class="font-EffraR">
            Simulation d'économies et de gains potentiels
          </h1>
          <h3 class="font-EffraR text-dokBlue-ultra hidden md:block">dok.ma</h3>
        </div>
        <!------------------------------ Temps de consultation ----------------------->
        <div class="flex flex-col gap-y-1 pb-5 md:pb-10">
          <label>Durée de la consultation</label>
          <VueSlider
            @change="changeTime()"
            v-model="consultationTime"
            :adsorb="true"
            :data="consultationTimes"
            :marks="true"
            :included="true"
            :tooltip="'always'"
            :process-style="{ backgroundColor: '#2E6BFF' }"
            :tooltip-style="{
              backgroundColor: '#2E6BFF',
              borderColor: '#2E6BFF',
            }"
          >
          </VueSlider>
        </div>

        <!------------------------------ Heures de travail ----------------------->
        <div class="flex flex-col gap-y-1 pb-5 md:pb-10">
          <label>Heures de travail par jour</label>
          <VueSlider
            @change="changeTime()"
            v-model="heureBase"
            :adsorb="true"
            :data="heureBases"
            :marks="true"
            :included="true"
            :tooltip="'always'"
            :process-style="{ backgroundColor: '#2E6BFF' }"
            :tooltip-style="{
              backgroundColor: '#2E6BFF',
              borderColor: '#2E6BFF',
            }"
          >
          </VueSlider>
        </div>

        <!------------------------------ Tarif de consultation ----------------------->
        <div class="flex flex-col gap-y-1 pb-10">
          <label>Tarif de la consultation</label>
          <VueSlider
            class="md:hidden block"
            @change="changeTime()"
            v-model="prixConsultation"
            :adsorb="true"
            :data="prixConsultations2"
            :marks="true"
            :tooltip="'always'"
            :included="true"
            :process-style="{ backgroundColor: '#2E6BFF' }"
            :tooltip-style="{
              backgroundColor: '#2E6BFF',
              borderColor: '#2E6BFF',
            }"
          >
          </VueSlider>
          <VueSlider
            class="hidden md:block"
            @change="changeTime()"
            v-model="prixConsultation"
            :adsorb="true"
            :data="prixConsultations"
            :marks="true"
            :tooltip="'always'"
            :included="true"
            :process-style="{ backgroundColor: '#2E6BFF' }"
            :tooltip-style="{
              backgroundColor: '#2E6BFF',
              borderColor: '#2E6BFF',
            }"
          >
          </VueSlider>
        </div>
      </div>
    </div>
    <!---- End simulation ------->

    <!---- sing up ------->
    <div class="container py-5 xl:px-2 mt-10">
      <h3 class="text-3xl text-black font-EffraM mb-5">
        {{ $t("create_account_dok") }}
      </h3>

      <form v-on:submit.prevent="onClick">
        <!-- Form -->
        <div
          class="grid xl:gap-x-10 gap-y-5 grid-cols-1 xl:grid-cols-2 mb-12"
          :class="
            errors && Object.keys(errors).length > 0 ? 'shake-elemnt ' : ''
          "
        >
          <div class="bg-grayfoot shadow-none xl:shadow rounded-cu py-8 px-5">
            <div class="flex justify-between items-center mb-5">
              <h3 class="text-3xl text-black font-EffraM">
                {{ $t("info_perso") }}
              </h3>

              <img
                src="/icons/checkIcon.png"
                alt="checked"
                class="block xl:hidden"
                v-if="
                  data.isAccepte &&
                  data.certification &&
                  data.email &&
                  data.gender &&
                  data.firstName &&
                  data.lastName &&
                  data.mobile
                "
              />
            </div>
            <div class="flex gap-x-8 mb-5">
              <div class="flex items-center">
                <input
                  type="radio"
                  id="homme"
                  class="
                    form-radio
                    border-2 border-solid border-gray-400
                    text-xl
                    mr-2
                    focus:outline-none focus:shadow-none
                  "
                  :class="errors.gender ? 'border-red-500' : ''"
                  v-model="data.gender"
                  value="MALE"
                />
                <label for="homme" class="font-EffraM text-xl">
                  {{ $t("homme") }}
                </label>
              </div>
              <div class="flex items-center">
                <input
                  type="radio"
                  id="femme"
                  class="
                    form-radio
                    border-2 border-solid border-gray-400
                    text-xl
                    mr-2
                    focus:outline-none focus:shadow-none
                  "
                  :class="errors.gender ? 'border-red-500' : ''"
                  v-model="data.gender"
                  value="FEMALE"
                />
                <label for="femme" class="font-EffraM text-xl">
                  {{ $t("femme") }}
                </label>
              </div>
            </div>
            <div class="flex flex-col md:flex-row gap-x-5 mb-5">
              <!--- First name ---->
              <dok-input
                size="lg"
                :d-placeholder="$t('lastName')"
                d-type="text"
                :d-model.sync="data.lastName"
                :custom-class="errors.lastName ? 'border-red-500' : ''"
                :label="$t('lastName')"
              >
              </dok-input>
              <!--- Last name ---->
              <dok-input
                size="lg"
                :d-placeholder="$t('firstName')"
                d-type="text"
                :d-model.sync="data.firstName"
                :custom-class="errors.firstName ? 'border-red-500' : ''"
                :label="$t('firstName')"
              >
              </dok-input>
            </div>
            <!--- Commerical name ---->
            <div class="w-full mb-5">
              <dok-input
                size="lg"
                :d-placeholder="$t('nameComercial')"
                d-type="text"
                :d-model.sync="data.localCommercialName"
                :custom-class="
                  errors.localCommercialName ? 'border-red-500' : ''
                "
                :label="$t('nameComercial')"
              >
              </dok-input>
            </div>

            <!--- Specialite ---->
            <div class="flex flex-col w-full mb-5">
              <label for="ville" class="font-EffraR text-base text-gray-800">
                {{ $t("patient.specialite") }}
              </label>
              <multiselect
                :searchable="true"
                :internal-search="true"
                :preserve-search="true"
                :multiple="true"
                v-model="data.landingPage.address.ObjectSpecialities"
                :options="specialites"
                :placeholder="$t('patient.specialite')"
                track-by="name"
                label="name"
                @input="onChangeSpecialty"
                class="doksingupselect"
                :class="errors['landingPage.specialities'] ? 'has-error' : ''"
              >
                <template v-slot:noResult> {{ $t("no_result") }} </template>
              </multiselect>
            </div>

            <!--- City ---->
            <div class="flex flex-col xl:flex-row xl:gap-x-5 mb-5">
              <div class="flex flex-col w-full xl:w-11/12 mb-5 xl:mb-0">
                <label
                  for="ville"
                  class="font-EffraR text-base text-gray-800"
                  >{{ $t("patient.ville") }}</label
                >
                <multiselect
                  :searchable="true"
                  :internal-search="true"
                  :preserve-search="true"
                  :multiple="false"
                  v-model="data.landingPage.objectCity"
                  :options="citys"
                  :placeholder="$t('patient.ville')"
                  track-by="name"
                  label="name"
                  @input="onChangeCity"
                  :class="errors['landingPage.address.city'] ? 'has-error' : ''"
                  class="doksingupselect"
                >
                  <template v-slot:noResult>
                    {{ $t("no_result") }}
                  </template>
                  <slot name="caret">X</slot>
                </multiselect>
              </div>
              <div class="w-full xl:w-100">
                <dok-input
                  size="lg"
                  d-placeholder="00000"
                  d-type="text"
                  :d-model.sync="data.landingPage.address.zipCode"
                  :custom-class="
                    errors['landingPage.address.zipCode']
                      ? 'border-red-500'
                      : ''
                  "
                  :label="$t('patient.postal')"
                >
                </dok-input>
              </div>
            </div>

            <!--- Address ---->
            <dok-input
              size="lg"
              :d-placeholder="$t('patient.address')"
              d-type="text"
              :d-model.sync="data.landingPage.address.label"
              :custom-class="
                errors['landingPage.address.label'] ? 'border-red-500' : ''
              "
              :label="$t('patient.address')"
            >
            </dok-input>
          </div>
          <div class="bg-grayfoot shadow-none xl:shadow rounded-cu py-8 px-5">
            <h3 class="text-3xl text-black font-EffraM mb-5">
              {{ $t("contact") }}
            </h3>
            <div
              class="flex flex-col md:flex-row lg:flex-col gap-y-3 gap-x-5 mb-5"
            >
              <dok-input
                size="lg"
                :d-placeholder="$t('phone_pro')"
                d-type="tel"
                d-pattern="(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?"
                :d-model.sync="data.mobile"
                :label="$t('phone_pro')"
                :custom-class="errors.mobile ? 'border-red-500' : ''"
              >
                <template v-slot:rightIcon>
                  <i
                    class="
                      far
                      fa-question-circle
                      cursor-pointer
                      px-3
                      tooltipinfo
                    "
                    v-tooltip="`${$getInfoBull('mobile')}`"
                  ></i>
                </template>
                <template v-slot:leftIcon>
                  <img src="/icons/tablet.png" :alt="$t('phone_pro')" />
                </template>
              </dok-input>

              <dok-input
                size="lg"
                :d-placeholder="$t('phone_fix')"
                d-type="text"
                :d-model.sync="data.fix"
                d-pattern="(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?"
                :label="$t('phone_fix')"
                :custom-class="errors.fix ? 'border-red-500' : ''"
              >
                <template v-slot:leftIcon>
                  <img src="/icons/phone.png" alt="téléphone fixe" />
                </template>
              </dok-input>

              <dok-input
                size="lg"
                :d-placeholder="$t('signin.email')"
                d-type="email"
                :d-model.sync="data.email"
                :label="$t('signin.email')"
                :custom-class="errors.email ? 'border-red-500' : ''"
              >
                <template v-slot:leftIcon>
                  <img src="/icons/mail.png" alt="email" />
                </template>
              </dok-input>
              <div>
                <dok-input
                  size="lg"
                  :d-placeholder="$t('signin.password')"
                  :d-type="!data.showPassword ? 'password' : 'text'"
                  :d-model.sync="data.password"
                  :label="$t('signin.password')"
                  :custom-class="
                    errors.password || errors.passwordStrong
                      ? 'border-red-500'
                      : ''
                  "
                >
                  <template v-slot:rightIcon>
                    <img
                      src="/icons/eye.png"
                      class="cursor-pointer"
                      @click="data.showPassword = !data.showPassword"
                      alt="voir le mot de passe"
                    />
                  </template>
                </dok-input>
                <div
                  v-if="data.password.length"
                  class="h-1 rounded-full my-2"
                  :style="`width: ${percentPassword()}%; background: ${percentBackground(
                    percentPassword()
                  )}`"
                ></div>
                <div class="flex flex-col" v-if="data.password.length">
                  <span
                    class="flex flex-row gap-x-1 items-center font-EffraR"
                    :class="
                      validation.has_length ? 'text-green-500' : 'text-red-500'
                    "
                  >
                    <i
                      class="far fa-check-circle"
                      v-if="validation.has_length"
                    ></i
                    ><i
                      class="far fa-times-circle"
                      v-if="!validation.has_length"
                    ></i>
                    {{ $t("validation_password.moins_chart") }}
                  </span>
                  <span
                    class="flex flex-row gap-x-1 items-center font-EffraR"
                    :class="
                      validation.has_number ? 'text-green-500' : 'text-red-500'
                    "
                  >
                    <i
                      class="far fa-check-circle"
                      v-if="validation.has_number"
                    ></i
                    ><i
                      class="far fa-times-circle"
                      v-if="!validation.has_number"
                    ></i>
                    {{ $t("validation_password.chiffres") }}
                  </span>
                  <span
                    class="flex flex-row gap-x-1 items-center font-EffraR"
                    :class="
                      validation.has_lowercase
                        ? 'text-green-500'
                        : 'text-red-500'
                    "
                  >
                    <i
                      class="far fa-check-circle"
                      v-if="validation.has_lowercase"
                    ></i
                    ><i
                      class="far fa-times-circle"
                      v-if="!validation.has_lowercase"
                    ></i>
                    {{ $t("validation_password.minuscules") }}
                  </span>
                  <span
                    class="flex flex-row gap-x-1 items-center font-EffraR"
                    :class="
                      validation.has_uppercase
                        ? 'text-green-500'
                        : 'text-red-500'
                    "
                  >
                    <i
                      class="far fa-check-circle"
                      v-if="validation.has_uppercase"
                    ></i
                    ><i
                      class="far fa-times-circle"
                      v-if="!validation.has_uppercase"
                    ></i>
                    {{ $t("validation_password.majuscules") }}
                  </span>
                  <span
                    class="flex flex-row gap-x-1 items-center font-EffraR"
                    :class="
                      validation.has_special ? 'text-green-500' : 'text-red-500'
                    "
                  >
                    <i
                      class="far fa-check-circle"
                      v-if="validation.has_special"
                    ></i
                    ><i
                      class="far fa-times-circle"
                      v-if="!validation.has_special"
                    ></i>
                    {{ $t("validation_password.caracteres_speciaux") }}
                  </span>
                </div>
              </div>
            </div>
            <div class="flex flex-col gap-y-2">
              <div class="flex flex-row items-start">
                <input
                  type="checkbox"
                  class="
                    form-checkbox
                    border-2 border-solid border-gray-400
                    rounded-none
                    text-2xl
                    mr-2
                    focus:outline-none focus:shadow-none
                  "
                  id="terms"
                  v-model="data.isAccepte"
                />
                <label
                  for="terms"
                  class="text-lg font-EffraM xl:font-EffraR text-black"
                  >{{ $t("condition_partone") }}
                  <span
                    class="cursor-pointer"
                    style="color: #1f49ad"
                    @click="onModal('CGU')"
                    >{{ $t("utilisation") }}</span
                  >
                  {{ $t("et_de") }}
                  <span
                    class="cursor-pointer"
                    style="color: #1f49ad"
                    @click="onModal('CGV')"
                    >{{ $t("vente") }}</span
                  >{{ $t("condition_parttwo") }}</label
                >
              </div>
              <div class="flex flex-row items-start">
                <input
                  type="checkbox"
                  class="
                    form-checkbox
                    border-2 border-solid border-gray-400
                    rounded-none
                    text-2xl
                    mr-2
                    focus:outline-none focus:shadow-none
                  "
                  id="certification"
                  v-model="data.certification"
                />
                <label
                  for="certification"
                  class="text-lg font-EffraM xl:font-EffraR text-black"
                >
                  {{ $t("certification") }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <!-- End Form -->

        <!-- Actions -->
        <div class="w-full xl:w-1/2 mx-auto mb-8">
          <div class="flex flex-col w-full">
            <dok-button
              type="submit"
              size="md"
              custom-class="uppercase"
              @click.native="onClick"
            >
              {{ $t("create_account") }}
            </dok-button>

            <ul>
              <li class="flex items-center font-EffraR py-1">
                <i class="fas fa-check mr-2"></i>
                {{ $t("patient.one_free_month") }}
              </li>
              <li class="flex items-center font-EffraR py-1">
                <i class="fas fa-check mr-2"></i>
                {{ $t("patient.abonnement_moment") }}
              </li>
            </ul>
          </div>
        </div>
      </form>
    </div>
    <!-- End singup -->

    <!-- Footer -->
    <dokFooter></dokFooter>
    <!-- End Footer -->

    <!-- Modal -->
    <modal
      :is-active="modal.is"
      :exit-modal="
        () => {
          modal.is = false;
          modal.type = null;
        }
      "
      :height="500"
      :width="950"
      :title="
        modal.type === 'CGU'
          ? 'CONDITIONS D’UTILISATION DOK.MA'
          : 'CONDITIONS D’ABONNEMENT DOK.MA'
      "
    >
      <cgu class="px-2" v-if="modal.type === 'CGU'"></cgu>
      <cgv class="px-2" v-if="modal.type === 'CGV'"></cgv>
    </modal>
  </div>
</template>
<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
const dokFooter = () => import("@/views/home/layouts/footer");
const modal = () => import("@/views/global-components/modal");
const cgu = () => import("@/views/statics/cgu");
const cgv = () => import("@/views/statics/cgv");
import algoliasearch from "algoliasearch/lite";
const functionProDok = () =>
  import("@/views/global-components/functionsDokPro");

export default {
  components: { VueSlider, dokFooter, functionProDok, modal, cgu, cgv },
  mounted() {
    this.changeTime();
  },
  data() {
    return {
      consultationTimes: ["10", "15", "20", "30", "45", "60", "90"],
      heureBases: ["4", "5", "6", "7", "8", "9", "10", "11", "12"],
      prixConsultations: [
        "100",
        "150",
        "200",
        "250",
        "300",
        "350",
        "400",
        "450",
        "500",
        "600",
        "800",
        "1000",
        "1500",
      ],
      prixConsultations2: [
        "100",
        "200",
        "300",
        "400",
        "500",
        "600",
        "800",
        "1000",
        "1500",
      ],
      consultationTime: "20",
      heureBase: "8",
      prixConsultation: "300",
      coutDokMensuel: 849,
      coutDokAnuel: 10188,
      consultationTimeRes: "",
      totalEcoMonth: "",
      totalEcoYear: "",

      errors: {},
      modal: { is: false, type: null },
      data: {
        password: "",
        isAccepte: false,
        certification: false,
        role: "DOCTOR",
        showPassword: false,
        landingPage: { address: { ObjectSpecialities: [] }, specialities: [] },
        professionalInfo: {},
      },
      validation: {
        has_number: false,
        has_lowercase: false,
        has_uppercase: false,
        has_special: false,
        has_length: false,
      },
      citys: [],
      specialites: [],
      algoliaKeys: algoliasearch(
        "2BJUOM1BDB",
        "7906f008c0346b78f39d051fa293387f"
      ),
    };
  },
  watch: {
    validation: {
      handler(newValue, oldValue) {
        this.percentPassword();
      },
      deep: true,
    },
    "data.password"(newVal, oldVal) {
      this.validation.has_number = /\d/.test(this.data.password);
      this.validation.has_lowercase = /[a-z]/.test(this.data.password);
      this.validation.has_uppercase = /[A-Z]/.test(this.data.password);
      this.validation.has_length = this.data.password.length > 7;
      this.validation.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(
        this.data.password
      );
    },
  },
  created() {
    this.onCitys();
    this.onSpecialites();
  },
  computed: {
    Errors() {
      return darkMode ? "dark-theme" : "light-theme";
    },
  },
  methods: {
    convertToDH(val) {
      const res = new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "MAD",
      }).format(val);
      return res.replace("MAD", "DH");
    },
    //D3
    NbrConsultationHeure() {
      return 60 / this.consultationTime; // 60 / D2
    },
    // D5
    NbConsultationJour() {
      return this.heureBase * this.NbrConsultationHeure(); // D4 * D3
    },
    // D6
    nbrTravailSemain() {
      return 5;
    },
    // D7
    NbTravailMensuel() {
      return this.nbrTravailSemain() * 4; // D6 * 4
    },
    // D8
    nbrConsultationMensuel() {
      return this.NbTravailMensuel() * this.NbConsultationJour(); // D7 * D5
    },
    // D10
    tempsMinEcoParConsultation() {
      return (this.consultationTime * 30) / 100; // D2 * 30%
    }, // D11
    tempEconomiserMensuel() {
      return this.tempsMinEcoParConsultation() * this.nbrConsultationMensuel(); //D10 * D8
    },
    // D12
    tempEconomiserHeure() {
      return this.tempEconomiserMensuel() / 60; //D11 / 60
    },
    // D13
    nbrConsultationEffectue() {
      return this.tempEconomiserMensuel() / this.consultationTime; // D11 / D2
    },
    // D15
    CAMensuelEstime() {
      return this.nbrConsultationMensuel * this.tempEconomiserMensuel; // D14 * D8
    },
    // D16
    totalCASuppplementMensuel() {
      this.totalEcoMonth =
        this.prixConsultation * this.nbrConsultationEffectue(); // D14 * D13
      return this.prixConsultation * this.nbrConsultationEffectue();
    },
    // D18
    totalCASuppplementAnnuell() {
      this.totalEcoYear = this.totalCASuppplementMensuel() * 12; // D16 * 12
    },
    changeTime() {
      this.totalCASuppplementMensuel();
      this.totalCASuppplementAnnuell();
      this.consultationTimeRes = this.tempsMinEcoParConsultation();
    },

    percentPassword() {
      let precent = 0;
      if (this.validation.has_number) precent = precent + 20;
      if (this.validation.has_lowercase) precent = precent + 20;
      if (this.validation.has_uppercase) precent = precent + 20;
      if (this.validation.has_special) precent = precent + 20;
      if (this.validation.has_length) precent = precent + 20;
      return precent;
    },
    percentBackground(val) {
      if (val === 0) return "#DDD";
      if (val === 20) return "#EF4444";
      if (val === 40) return "#DB2777";
      if (val === 60) return "#D97706";
      if (val === 80) return "#fbbf24";
      if (val === 100) return "#10B981";
    },
    onChangeCity(value) {
      this.data.landingPage.address.city = value ? value.objectID : null;
    },
    onChangeSpecialty(value) {
      let specialites = value.map((spec) => {
        return spec.objectID;
      });
      this.data.landingPage.specialities = specialites;
    },
    onModal(type) {
      this.modal = { type, is: true };
    },
    async onCitys() {
      const index = this.algoliaKeys.initIndex(
        "dokma_dev_cities_principal_first"
      );
      const data = await index.search("", {
        hitsPerPage: 618,
      });
      this.citys = data.hits;
    },
    async onSpecialites() {
      const index = this.algoliaKeys.initIndex("dokma_dev_specialities");
      const data = await index.search("", {
        hitsPerPage: 618,
      });
      this.specialites = data.hits;
    },
    async onClick() {
      event.preventDefault();
      if (!this.data.isAccepte || !this.data.certification) {
        return this.$vs.notify({
          time: 4000,
          text: "Merci de cocher les cases pour finaliser la création de votre compte",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-triangle",
        });
      }

      if (this.percentPassword() !== 100) {
        this.errors = {
          passwordStrong: "Password not valid",
        };

        return this.$forceUpdate();
      }

      this.$vs.loading();

      for (let item of this.data.landingPage.address.ObjectSpecialities) {
        if (item.name == "Pharmacie") {
          this.data.role = "PHARMACY";
          break;
        }
        if (item.name == "Biologiste (Laboratoire d'analyse)") {
          this.data.role = "LABORATORY";
          break;
        }
      }

      const { data } = await this.$store.dispatch("user/REGISTER", this.data);
      // Validation Inputs
      if (!data.ok) {
        if (data.errors && Object.keys(data.errors).length) {
          this.$vs.loading.close();
          return (this.errors = data.errors);
        }
        this.$vs.loading.close();
      }
      if (data.ok) {
        if (!data.data.isActive) {
          this.$router.push({
            name: "verficationEmail",
            query: {
              email: data.data.email,
            },
          });
        }

        this.errors = [];
        this.data = {
          password: "",
          isAccepte: false,
          certification: false,
          role: "DOCTOR",
          showPassword: false,
          landingPage: {
            address: { ObjectSpecialities: [] },
            specialities: [],
          },
          professionalInfo: {},
        };
      }
      this.$vs.loading.close();
      this.$vs.notify({
        time: 4000,
        text: data.ok ? "Compte créé avec succès" : data.message,
        color: data.ok ? "success" : "danger",
        iconPack: "feather",
        icon: "icon-check",
      });
    },
    async findSpecialite(term) {
      const { ok, data } = await this.$store.dispatch(
        "doctor/GET_SPECIALITIES_LIST",
        {
          params: {
            term,
            limit: 1,
          },
        }
      );
      if (ok && data[0]) {
        this.data.landingPage.address.ObjectSpecialities.push({
          name: data[0].name,
          slug: data[0].slug,
          objectID: data[0]._id,
        });
        this.onChangeSpecialty(
          this.data.landingPage.address.ObjectSpecialities
        );
      }
    },
    async onInpeSearch() {
      event.preventDefault();
      if (!this.data.professionalInfo.INPE) {
        return this.$vs.notify({
          time: 4000,
          text: "Please enter the INPE",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-triangle",
        });
      }
      this.data.firstName = null;
      this.data.lastName = null;
      this.data.fix = null;
      this.data.landingPage.address.label = null;
      this.data.landingPage.address.ObjectSpecialities = null;
      this.$vs.loading();
      await this.$store.dispatch("doctor/PREFILL_BY_INPE", {
        params: { inpe: this.data.professionalInfo.INPE },
        onData: ({ ok, data }) => {
          if (!ok) {
            return this.$vs.notify({
              time: 4000,
              text: "L'INPE Entry n'est pas enregistré chez nous",
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-triangle",
            });
          }
          if (ok) {
            let fullname = data.nom.split(" "),
              firstName = fullname.pop(),
              lastName = fullname.join(" ") || "";
            this.data.firstName = firstName || null;
            this.data.lastName = lastName || null;
            this.data.fix = data.phone;
            this.data.landingPage.address.label = data.address;
            this.data.role = data.role || "PATIENT";
            if (data.role === "PHARMACY" || data.role === "LABORATORY") {
              this.data.localCommercialName = data.nom;
            }
            if (data.speciality) {
              this.findSpecialite(data.speciality);
            }
            this.$forceUpdate();
          }
        },
      });
      this.$vs.loading.close();
    },
  },
};
</script>

<style lang="scss">
.line-between {
  @apply font-EffraM text-lg text-gray-600;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 1.2rem;
  align-items: center;
  &:before,
  &:after {
    content: "";
    display: block;
    height: 2px;
    @apply bg-gray-300;
  }
}

.doksingupselect .multiselect__tag-icon:hover {
  background: #97b5ff !important;
  color: #2e6bff !important;
}

.doksingupselect .multiselect__tag {
  background: #e4e4e4 !important;
  color: #2e6bff !important;
}

.doksingupselect .multiselect__tags {
  min-height: 50px !important;
}

.has-error .multiselect__tags {
  border: 2px solid #ef4444 !important;
}

.doksingupselect .multiselect__tags {
  border: 2px solid #e4e4e4;
}

.doksingupselect .multiselect__select {
  height: 45px !important;
}

.doksingupselect .multiselect__placeholder {
  padding-top: 5px !important;
  color: #4f5968;
  @apply text-lg;
  /*#585858*/
}

.doksingupselect .multiselect__input,
.doksingupselect .multiselect__single {
  line-height: 30px;
  @apply font-EffraR;
}

.doksingupselect .multiselect__option--highlight {
  @apply bg-dokBlue-light;
}
.doksingupselect .multiselect__option--selected.multiselect__option--highlight {
  @apply bg-rosDok-light;
}
</style>
